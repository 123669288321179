import type { Entry } from "contentful";
import type {
  TypeArticleSkeleton,
  TypeExternalPageSkeleton,
  TypeReportSkeleton,
} from "~/types/contentful";

export const hasThemeOrTopic = (
  content: Entry,
): content is Entry<
  TypeArticleSkeleton | TypeReportSkeleton | TypeExternalPageSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.themeOrTopic;

export const hasImage = (
  content: Entry,
): content is Entry<
  TypeArticleSkeleton | TypeExternalPageSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.image || !!content.fields.thumbnail;

export const hasSubcopy = (
  content: Entry,
): content is Entry<
  TypeExternalPageSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.subcopy;

export const hasSubtitle = (
  content: Entry,
): content is Entry<
  TypeArticleSkeleton | TypeReportSkeleton,
  "WITHOUT_UNRESOLVABLE_LINKS",
  "en-GB"
> => !!content.fields.subcopy;
